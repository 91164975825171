
body.pc{
    .base_input {
        &.size_datepicker {
            input {
                width: 126px;
            }
            &.add_time {
                input {
                    width: 100%;
                }
            }
        }
        &.small {
            input {
                width: 100px;
            }
        }
        &.medium {
            input {
                width: 300px;
            }
        }
        &.large {
            flex-grow: 1;
            width: 100%;
            input {
                width: 100%;
                max-width: 518px;
            }
            &.email {
                input {
                    max-width: inherit;
                }
            }
        }
        &.auto {
            input {
                width: auto;
            }
        }
        &.fixed {
            input {
                width: 174px;
            }
        }
    } 
}
@use '../../../../../mixin' as mx;
@use '../../../../../setting' as st;

.estimate_meisai_sp {
  @include mx.flex_direction_column;

  &__header {
    @include mx.flex_box;
    @include mx.flex_align_center;
    overflow-y: auto;
    > div {
      > span {
        white-space: nowrap;
      } 
    }
    gap: 0.5em;
    // gap: 0.25em;
    padding: 1rem;
    width: 100%;
    background-color: st.$header_background_gray;
    font-weight: bold;
    &__2th {
      flex: 1;
      text-align: right;
      i {
        background-color: #000;
        color: #FFF;
        border-radius: 50%;
        width: 42px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        font-size: 1.5em;
      }
    }
    &__last {
      @include mx.flex_box;
      gap: 0.5em;
      // gap: 0.25em;
      .copy_past_mode {
        border-radius: 50%;
        background-color: #000;
        i {
          font-size: 1.5em;
          color: #fff;
        }
      }
    }
  }
}

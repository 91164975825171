@import "../../../../App.scss";
body.pc {
    .search_area {
        position: relative;
        padding-bottom: 45px;
        max-height:300px;
        @include animationSlow();
        .search_detail {
            display: none;
            border-top: 1px solid $border_default_color;
            padding-top: 5px;
            
            .item_wrap {
                flex-wrap: wrap;
            }
        }
        .btn_box {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 0;
            @include radius(0);
            button {
                //@include animationSlow();
                transition: all 300ms 0s ease;
                @include radius(0);
                background-color: $base-color;
                width: 100%;
                color: #FFF;
                font-size: 11px;
                height: 24px;
                min-height: 24px !important;
                &:hover {
                    background-color: rgba($color: $base-color, $alpha: 0.75) !important;
                    color: #FFF !important;
                }
                &:focus {
                    background-color: rgba($color: $base-color, $alpha: 1) !important;
                    color: #FFF !important;
                }
                &.for_detail_up {
                    display: none;
                }
                &.for_detail_down {
                    display: block;
                }
            }
        }
       //search_detail表示時
       &.detail_on {
            /*max-height:100vh;*/
            max-height: inherit;
            .for_simple {
                display: none !important;
            }
            .search_detail {
                display: block;
            }
            .btn_box {
                button {
                    &.for_detail_up {
                        display: block;
                    }
                    &.for_detail_down {
                        display: none;
                    }
                }
            }
        }
        .size_adjustment {
            select {
                min-width: 174px !important;
            }
            &.width_datepicker {
                input {
                    width: 126px;
                }
            }
        }
    }
}
@import "../../../../App.scss";
@import "../../../../mixin.scss";

.support_history_card {
  .card_base_row {
    &__col_right {
      width: 5em !important;
      .thumb {
        img {
          /*width:auto;
          height:auto;
          max-width:100%;
          max-height:100%;*/
          width:100%;
          height:100%;
          object-fit: cover;
          //object-fit: contain;
        }
      }
      .date {
        text-align: center;
        background-color: $emphasis_color2;
        font-size: 0.9rem;
      }
      .rect_label {
        margin-bottom: 0 !important;
      }
    }
  }
}

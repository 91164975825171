@import "../../../../mixin";

body.sp {
  .detail_page_footer {
    &_col {
      flex: 1;
      &:first-of-type {
        display: flex;
        > .icon_button {
          width: calc(100% / 3 - 10px) !important;
          height: 100%;

          @include mq_max(sm) {
            width: calc(100% / 3 - 5px) !important;
            &:not(:first-of-type) {
              margin-left: 5px !important;
            }
          }
        }
      }
      &:last-of-type {
        display: flex;
        > .btn {
          width: calc(100% / 2) !important;
          min-width: 6em;
          display: block;
          &:last-of-type {
            margin-left: auto !important;
          }
          + .btn {
            margin-left: 5px !important;
          }
        }
      }
    }
  }
}

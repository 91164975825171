@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.projectEditSP {
  .category_wrap {
    .item_wrap {
      /**
       * 個別対応
       */

      /* 顧客ID */
      .item_customerID {
        @include flex_box();
        @include flex_align_center();
        > div {
          flex: 1;
          input {
            width: 100%;
          }
        }
        > .btn {
          width: 90px;
          margin-left: 10px;
        }
      } //.item_customerID

      /* 地図から検索ボタン */
      .item_map > .btn {
        display: block;
        margin-left: auto;
      }

      .item_body {
        // 顧客名
        &.item_customer {
          @include flex_box();
          @include flex_align_end();
          > div {
            &:first-of-type {
              flex: 4;
              * {
                width: 100% !important;
              }
            }
            &:not(:first-of-type) {
              margin-left: 10px;
              flex: 1;
            }
            min-width: inherit;
          }
        }

        // 顧客ランク
        &.item_customerRank {
          @include flex_box();
          @include flex_align_center();
          > div {
            &:first-of-type {
              flex: 3;
            }
            &:not(:first-of-type) {
              margin-left: 10px;
              flex: 2;
            }
            min-width: inherit;
          }
        } //&.item_customerRank

        // エリア
        &.item_area,
        &.item_madori {
          > div {
            width: 160px !important;
            min-width: inherit;
          }
        }
      } //.item_body
    } //.item_wrap
  } //.category_wrap
} // customerEditSP

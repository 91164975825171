.TopLabelInputField_pc {
  &__label {
  }
  &__field {
  }
}

.TopLabelInputField_sp {
  &__label {
    display: block;
  }
  &__field {
    margin-top: 3px;
    display: block;
    .ui.input {
      width: 100%;
    }
  }
}

@import "../../../../../../App.scss";

.OrderListPC {
  width: 100%;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none; /* scroll 非表示 IE, Edge 対応 */
  scrollbar-width: none; /* scroll 非表示 Firefox 対応 */
  &::-webkit-scrollbar {
    display: none; /* scroll 非表示 chrome, safari */
  }
  /*
  .card-wrapper {
    border-top: solid 2px $border_default_color;
    &:last-child {
      border-bottom: solid 2px $border_default_color;
    }
    &:nth-child(odd) {
      .customer_card-pc {
        background-color: #FFF;
      }
    }
  }*/
}

@import "../../../../mixin.scss";
@import "../../../../App.scss";
@import "../../../../setting.scss";

.header_sp {
  $header_height: 4rem;
  @include flex_box();
  @include flex_align_center();
  background-color: $base_color;
  height: $header_height;
  font-size: 1rem;
  padding: 1rem;

  @include mq_max() {
    &_menu_btn,
    &__search_box_btn {
      // transition: 0.5s;
      &.search_focused {
        transition-property: flex;
        transition-duration: 0.5s;
        flex: none !important;
        i,
        img {
          display: none;
        }
      }
    }

    &__search {
      &.search_focused {
        margin: 0;
        // margin: 0 0.5rem;
      }
    }
  }

  &_menu_btn {
    cursor: pointer;
    font-size: 2.5rem;
    @include mq_max(xs) {
      font-size: 2rem;
    }
    color: #ffffff;
    flex: 1;
    text-align: center;
  }

  &__search {
    /*flex: 4;*/
    flex: 10;
    @include flex_box();
    @include flex_align_center();
    @include flex_content_center();
    margin: 0 0.6rem 0 0.9rem;
    position: relative;
    form {
      @include flex_box();
      @include flex_align_center();
      width: 100%;
      height: 2.3rem;
      .base_input {
        width: 100%;
        //max-width: 40em;
        margin: auto;
        height: 100%;
        .input {
          width: 100%;
          height: inherit;
          input {
            padding: 0 1rem !important;
            font-size: 1rem;
            height: inherit;
            @include mq_max(sm) {
              padding: 0 0.7em !important;
            }
            // @include mq_max(sm) {
            //   padding: 0.5rem 0.75rem;
            //   font-size: 0.9rem !important;
            // }
            // @include mq_max(xs) {
            //   font-size: 0.8rem !important;
            //   padding: 0.5rem;
            // }

            &::placeholder {
              font-size: 1rem;
              @include mq_max(sm) {
                font-size: 0.9rem !important;
              }
              @include mq_max(xs) {
                font-size: 0.75rem !important;
              }
            }
          }
        }
      }
    }
  }

  &__search_box_btn {
    /*font-size: 3rem;
    @include mq_max(xs) {
      font-size: 2.5rem;
    }
    flex: 1;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    img {
      width: 1em;
    }*/
    position: absolute;
    font-size: 2rem;
    right: 5px;
    color: $base-color;
  }

  &__menu_content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: -100%;
    padding: 0 !important;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.3s;
    z-index: 110;

    &.opened {
      left: 0;
      visibility: visible;
      pointer-events: auto;
      transition: all 0.3s;
    }

    &_bg {
      height: 100%;
      background: #ffffff;
    }

    &__header {
      @include flex_box();
      @include flex_align_center();
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $header_height;
      padding: 0 1rem;
      background-color: $base_color;
      color: #ffffff;
      z-index: 150;
      &__close {
        $close_btn_size: 2rem;
        width: $close_btn_size;
        height: $close_btn_size;
        font-size: $close_btn_size;
        margin-left: auto;
        cursor: pointer;
      }
    } // header_sp__menu_content__header

    /* ========== ボディ ========== */
    &__body {
      $category_label_size: 1.2rem;
      @include scroll_display_none();
      position: absolute;
      width: 100%;
      overflow-y: auto;
      z-index: 140;
      padding: 0 0.5rem 0.5rem;

      /* ===== メインメニュー ===== */
      .main_menu {
        @include flex_box();
        @include flex_container_wrap();
        @include flex_content_center();
        width: 100%;
        background-color: #fff;
        &_btn {
          @include flex_box();
          @include flex_direction_column();
          @include flex_align_center();
          width: calc(100% / 3.3);
          margin: 0 0 1rem;
          @include mq_min() {
            width: calc(100% / 5);
          }
        }
      } // header_sp__menu_content__body__main-menu

      /* ===== メニューリスト ===== */
      &__menu_list {
        border-top: 1px solid $border_secondary;
        border-bottom: 1px solid $border_secondary;
        padding: 2.5rem 0.5rem;
        .menu {
          &:not(:first-of-type) {
            margin-top: 2rem;
          }
          &__category {
            font-size: $category_label_size;
            font-weight: bold;
            padding: 0.5rem 0;
          }
          &__item {
            &:not(:last-of-type) {
              margin-bottom: 0.5rem;
            }
            &:hover {
              background-color: $base_color_thin;
            }
          }
        }
      } // header_sp__menu_content__body__list_menu

      &__bottom {
        padding: 2rem 0.5rem;

        .user_info {
          &_label {
            @include flex_box();
            @include flex_align_center();
            @include flex_space_between();
            font-size: $category_label_size;
            font-weight: bold;
          }

          &_table {
            margin: 0.5rem 0 1rem;
          }
        }

        .btn {
          margin-left: auto;
          width: 8.6em;
        }
      }
    } // header_sp__menu_content__body
  } // header_sp__menu_content
}

@import "../../../setting";
@import "../../../mixin";

.file_upload_button {
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  color: $btn_secondary_color;
  background-color: $btn_secondary_background_color;
  border: solid 1px $btn_secondary_background_color;
  border-radius: 0.28571429rem;
  padding: 0 0.5em;
  user-select: none;
  font-size: 1rem;
  width: 7.5em;

  &:active {
    color: $btn_secondary_background_color;
    background-color: $btn_secondary_color;
  }

  input[type="file"] {
    display: none;
  }

  &.pc {
    min-width: 120px;
    height: 30px;
    &:hover {
      color: $btn_secondary_background_color;
      background-color: $btn_secondary_color;
    }
  }

  &.sp {
    height: 42px;
  }
}

@import "../../../../App.scss";
@import "../../../../mixin.scss";

.horizontalCalendar {
  > .react-datepicker-wrapper {
    display: none;
  }
  > .react-datepicker__tab-loop {
    > .react-datepicker-popper {
      position: static !important;
      transform: none !important;
      margin-top: 0;
      padding-top: 0;
      > div > .react-datepicker {
        width: 100%;
        border-radius: 0;
        border-top: none;
        border-right: none;
        border-left: none;
        > .react-datepicker__triangle {
          display: none !important;
        }
        > .react-datepicker__month-container {
          width: 100%;

          /* header */
          > .react-datepicker__header {
            width: 100%;
            padding: 0.5rem 0;
            > div {
              .preMonthBtn {
              }
            }
            > .react-datepicker__day-names {
              display: none;
            }
          }

          /* contents */
          > .react-datepicker__month {
            @include flex_box();
            @include scroll_display_none();
            width: 100%;
            overflow-x: auto;
            margin: 0.1rem 0;
            > .react-datepicker__week {
              > .react-datepicker__day {
                width: 3rem;
                line-height: 2rem;
                font-size: 14px;
                &.react-datepicker__day--outside-month {
                  display: none;
                }
                &.react-datepicker__day--today{
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
}

@import '../../../../../mixin.scss';
@import '../../../../../App.scss';

.changePasswordErrorSP {
  text-align: center;
  padding: 0 40px;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  height: 100%;
  &__title {
    font-size: 1.6rem;
    color: $base-color;
  }
  &__body {
    margin-top: 30px;
    &__inner {
      &__text {
        text-align: left;
        margin-top: 20px;
        font-size: 14px;
        &:first-child {
          margin-top: 0;
        }
      }
      &__btn {
        .btn {
          margin-top: 30px;
          width: 200px;
        }
      }
    }
  }
}
.changePasswordCompleteSP__body__inner__btn {
  margin-top: 10px !important;
}
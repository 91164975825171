@charset "UTF-8";

*,
::before,
::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1rem;
  margin: 0 !important;
}
img {
  vertical-align: middle;
}
.gm-style img {
  vertical-align: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

hr {
  height: 1px;
  margin: 6px 0;
  border: none;
  background: $base-color;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: $scrollbar_bg_color;
  border: none;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: $base_color;
  border-radius: 4px;
  box-shadow: none;
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}
.alert {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0;
  color: $error_text_color;
  font-weight: bold;
}
.require {
  color: $alert_color;
}
.strong {
  font-weight: bold;
}
.base_color {
  color: $base_color;
}

.display_none {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
  pointer-events: none;
}

button {
  padding: 0 10px;
  background-color: #fff;
  cursor: pointer;
}
/*form*/
textarea {
  @include border-default();
  @include radius(4px);
  width: 100%;
}
.ui.input {
  vertical-align: top;
}
.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
  > input {
    background-color: $form_disabled_color !important;
  }
}

.ui.selection.dropdown {
  @include radius(4px);
  vertical-align: top;
  line-height: 28px;
  min-height: 30px;
  height: 30px;
  padding: 0 30px 0 10px;
  /*margin-left: 5px;*/
  /*width: auto;*/
  min-width: /*100px*/ auto;
  .menu > .item {
    padding: 10px 10px !important;
    white-space: nowrap;
    &:hover {
      background-color: $base-color-thin;
      color: $base-color;
    }
  }
}
.ui.selection.dropdown .menu {
  width: inherit !important;
  border-top-width: 1px !important;
}
.ui.upward.selection.dropdown .menu {
  border-bottom-width: 1px !important;
}
.ui.dropdown .menu .selected.item,
.ui.dropdown.selected {
  color: #ffffff !important;
  background-color: $base-color !important;
  font-weight: normal;
}

.ui.dropdown > .dropdown.icon:before {
  content: none;
  position: absolute;
  top: 12px;
  right: 7px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: $form_text_color transparent transparent transparent;
  pointer-events: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

//datapickerのdisabled
.MuiInputBase-root.Mui-disabled {
  color: rgba(0,0,0,.87);
}

.table_responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  @include border-default();
  position: relative;
}
.table_responsive table {
  table-layout: fixed;
  min-width: 100%;
  border: none;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include border-default();
  border: none;
  tr {
    *:first-child {
      border-left: none;
    }
    *:last-child {
      border-right: none;
    }
    &:first-child {
      th {
        border-top: none;
      }
    }
    &:last-child {
      td {
        //border-bottom: none;
      }
    }
  }

  .no_data_guidance {
    width: calc(100% - 1px);
    height: calc(100% - 2.4em); // 2.4em ... theadの高さとスクロールが発生しない大きさを考慮
  }
}
table {
  font-size: 14px;
  background-color: #fff;
  th,
  td {
    white-space: nowrap;
    padding: 5px 10px;
    @include border-default();
  }
  thead {
    th {
      background-color: $base-color;
      color: #fff;
      font-weight: normal;
      text-align: center;
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        td {
          background-color: $table_td_even_color !important;
        }
      }
      &:nth-child(odd) {
        td {
          background-color: $table_td_odd_color !important;
        }
      }
    }
  }
}

.ui.modal > .header {
  padding: 10px 10px;
}

body.pc {
  .ui.attached.tabular.menu {
    a,
    button.item {
      @include box_shadow();
      @include border-default();
      @include radiusDetails(4px, 4px, 0px, 0px);
      padding: 0 30px;
      border-bottom: none;
      background-color: #fff;

      + a,
      + button.item {
        margin-left: 5px;
      }
      &:hover {
        background-color: $base-color;
        border-color: $base-color;
        color: #fff;
      }
      &.active {
        background-color: $base-color;
        border-color: $base-color;
        color: #fff;
      }
    }
  }
  .ui.attached:not(.message) + .ui.attached.segment:not(.top) {
    @include box_shadow();
    //border: none;
    padding: 15px;
    @include radius(0px);
    .btn_box {
      display: flex;
      margin-bottom: 5px;
      button {
        + .right_box {
          margin-left: auto;
          display: flex;
        }
        + button {
          margin-left: auto;
          /*&.exception {
            margin-left: 10px;
          }*/
        }
      }
      &.right {
        //justify-content: flex-end;
        button {
          margin-right: auto;
          + button {
            margin-left: 10px;
            margin-right: inherit;
          }
        }
      }
    }
    table {
      min-width: 100%;
    }
  }
}
.mr_0 {
  margin-right: 0 !important;
}
.mr_5 {
  margin-right: 5px !important;
}
.mr_10 {
  margin-right: 10px !important;
}
.mr_15 {
  margin-right: 15px !important;
}
.mr_20 {
  margin-right: 20px !important;
}
.ml_0 {
  margin-left: 0px !important;
}
.ml_5 {
  margin-left: 5px !important;
}
.ml_10 {
  margin-left: 10px !important;
}
.ml_15 {
  margin-left: 15px !important;
}
.ml_20 {
  margin-left: 20px !important;
}
.ml_auto {
  margin-left: auto !important;
}
.mt_0 {
  margin-top: 0px !important;
}
.mt_5 {
  margin-top: 5px !important;
}
.mt_10 {
  margin-top: 10px !important;
}
.mt_15 {
  margin-top: 15px !important;
}
.mb_0 {
  margin-bottom: 0px !important;
}
.mb_5 {
  margin-bottom: 5px !important;
}
.mb_10 {
  margin-bottom: 10px !important;
}
.py_0 {
  //padding vertical
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px_5 {
  //padding horizontal
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pl_5 {
  padding-left: 5px !important;
}
.pr_5 {
  padding-right: 5px !important;
}

.text_left { text-align: left;}
.text_right { text-align: right;}
.text_center { text-align: center;}

.flex_grow_1 {
  flex-grow: 1;
}

/*dialog*/
.ui.modal {
  max-width: 90%;
  max-height: 90%;
}
.BaseDialog__header__close {
  cursor: pointer;
}

/* レイアウト系 */
.padding_hor_1 {
  padding-right: 1em !important;
  padding-left: 1em !important;
}

.space_between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
}

.flex_box {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex_space_between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -ms-flex-pack: justify;
}

.flex_content_center {
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.flex_content_end {
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.flex_align_center {
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex_direction_column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex_1 {
  flex: 1;
}

body.sp {
  .ui.attached.tabular.menu {
    border-bottom: none;
    height: 2em;
    a {
      @include radiusDetails(0, 0, 0px, 0px);
      padding: 0 !important;
      box-shadow: none;
      border: none;
      background-color: #fff;
      @include bottom_border_box();

      + a {
        margin-left: 5px;
      }
      &:hover {
        background-color: #fff;
        color: #000000;
      }
      &.active {
        background-color: #fff;
        color: #000000;
        font-weight: normal;
        &.item {
          margin-bottom: 0;
          &::after {
            transform: scale(1);
          }
        }
      }
    }
  }
  .ui.attached:not(.message) + .ui.attached.segment:not(.top) {
    border: none;
    box-shadow: none;
    padding: 0.5rem 0.25rem;
    @include radius(0px);
  }

  /* テーブル風スタイル */
  .row_table_style {
    width: 100%;
    .t_row {
      @include flex_box();
      &:not(:first-of-type) {
        //margin-top: 0.5rem;
        margin-top: 0.25rem;
      }

      > .t_header {
        @include flex_box();
        @include flex_align_center();
        @include flex_content_center();
        min-width: 8em;
        width: 40vw;
        max-width: 9em;
        background-color: $table_thead_background_color;
        padding: 0.5em;
      }

      > .t_body {
        @include flex_box();
        @include flex_align_center();
        flex: 1;
        padding-left: 0.5rem;
        word-break: break-all;

        &.e_mail {
          > div {
            &:first-of-type {
              flex: 1;
            }
            &:last-of-type {
              .icon_button {
                margin-left: auto;
                min-height: 0 !important;
                padding-top: 0.25rem !important;
                padding-bottom: 0.25rem !important;
              }
            }
          }
        }
      }

      &.dir_column {
        @include flex_direction_column;
        height: auto;
        max-height: 16em;
        // overflow: hidden;

        .t_header {
          max-width: 100% !important;
          width: 100%;
        }

        .t_body {
          @include flex_box;
          @include flex_direction_column;
          flex: 1;
          overflow: auto;
          border: 3px solid $table_thead_background_color;
          padding-left: 0;

          .card {
            &:not(:last-of-type) {
              position: relative;
              &::before {
                display: block;
                content: '';
                width: 96%;
                height: 2px;
                background-color: rgba($color: #000000, $alpha: 0.1);
                position: absolute;
                top: calc(100% - 1px);
                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    }
  }

  /* プルダウン */
  .pulldown_select {
    label {
      width: 100%;
      .ui.dropdown {
        width: 100%;
      }
    }
  }
}

.dot_border {
  position: relative;
  height: 2px;
  &::before {
    content: "";
    background-image: linear-gradient(
      to right,
      #707070,
      #707070 2px,
      transparent 2px,
      transparent 6px
    );
    background-size: 6px 2px;
    background-repeat: repeat-x;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.icon_btn {
  i,
  img {
    margin-right: 5px;
  }
}
.icon_button {
  &.btn_tips {
    @include btn_tips();
  }
}

//領域可変用のバー
.vertical_bar {
  display: flex;
  background-color: inherit;
  color: $base_color;
  width: 100%;
  height: 100%;
  align-items: center;
  > div {
    width: 100%;
    /*height: 100%;*/
    /*line-height: 100%;*/
    i {
      transform: rotate(90deg);
    }
  }
}
.horizontal_bar {
  background-color: $base_color;
  text-align: center;
  color: #fff;
  height: 24px;
  line-height: 24px;
}

.triangle_icon {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;

  &::before {
    display: block;
    content: "";
    height: 0;
    width: 0;
    border-top: 0.75em solid currentColor;
    border-right: 0.5em solid transparent;
    border-left: 0.5em solid transparent;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  &.right {
    &::before {
      transform: rotate(-90deg);
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.nowrap {
  white-space: nowrap;
}

.file_name_box {
  display: block;
  width: 100%;
  line-height: 1.5em;
  color: #FFF;
  text-align: center;
}
.file_xlsx_color { background-color: $file_xlsx_color;}
.file_xls_color { background-color: $file_xls_color;}
.file_doc_color { background-color: $file_doc_color;}
.file_docx_color { background-color: $file_docx_color;}
.file_ppt_color { background-color: $file_ppt_color;}
.file_pptx_color { background-color: $file_pptx_color;}
.file_pdf_color { background-color: $file_pdf_color;}
.file_jpg_color { background-color: $file_jpg_color;}
.file_jpeg_color { background-color: $file_jpeg_color;}
.file_png_color { background-color: $file_png_color;}
.file_gif_color { background-color: $file_gif_color;}
.file_svg_color { background-color: $file_svg_color;}
.file_mp4_color { background-color: $file_mp4_color;}
.file_m4a_color { background-color: $file_m4a_color;}
.file_avi_color { background-color: $file_avi_color;}
.file_mov_color { background-color: $file_mov_color;}
.file_wmv_color { background-color: $file_wmv_color;}
.file_flv_color { background-color: $file_flv_color;}
.file_mkv_color { background-color: $file_mkv_color;}
.file_mpg_color { background-color: $file_mpg_color;}
.file_webm_color { background-color: $file_webm_color;}
.file_wave_color { background-color: $file_wave_color;}
.file_aif_color { background-color: $file_aif_color;}
.file_mp3_color { background-color: $file_mp3_color;}
.file_aac_color { background-color: $file_aac_color;}
.file_flac_color { background-color: $file_flac_color;}
.file_zip_color { background-color: $file_zip_color;}
@import "../../App.scss";
@import "../../mixin.scss";
@import "../../setting";

/* semantic */
.top-dimmer {
  background-color: rgba($color: #000000, $alpha: 0.7) !important;
  transition: 0s !important;
}
.under-dimmer {
  background: none !important;
  transition: 0s !important;
}

.ui.modal {
  border-radius: 0.4rem !important;
}

.scrolling.dimmable > .dimmer {
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .ui.modal {
    > .header {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
      padding-right: 1rem !important;
    }
    > .content {
      padding: 0 !important;
    }
  }
}

body.sp {
  .thumb {
    .ui.dimmer {
      background-color: $border_default_color;
      z-index: 100;
    }
  }
}

/* BaseDialog */
.base_dialog {
  &.sp {
    @include mq_min() {
      max-width: 80% !important;
    }
    @include mq_min(lg) {
      max-width: 70% !important;
    }

    &.flex_height {
      .base_dialog_content {
        > div {
          height: 100% !important;
          .base_dialog_content_inner {
            max-height: calc(90vh - 3rem);
          }
        }
      }
    }
  }

  .header {
    background-color: $base_color !important;
    color: $font-white !important;
    font-weight: normal !important;
  }
  &__header {
    display: flex !important;
    @include flex_align_center();
    padding-top: 0.5rem !important;
    &__title {
      font-size: 1rem;
      flex: 1;
      // @include mq_min(sm) {
      //   font-size: 16px;
      // }
      // @include mq_min(md) {
      //   font-size: 18px;
      // }
      // @include mq_max(sm) {
      //   font-size: 14px;
      // }
      // @include mq_max(xs) {
      //   font-size: 10px;
      // }
    }
    &__close {
      cursor: pointer;
      font-size: 1.5rem;
      padding-left: 5px;
      padding-right: 5px;
      text-align: center;
    }
  } // BaseDialog__header

  &_content {
    @include flex_box();
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    @include flex_direction_column();
    @include scroll_display_none();
    overflow-y: auto;
    padding: 0 !important;
    max-height: 84vh;

    &__close {
      text-align: right;
      font-size: 1.5rem;
      padding-right: 5px;
    }

    &_inner {
      @include flex_box();
      @include flex_direction_column();
      &_body {
        flex: 1;
        overflow-y: scroll;
        @include scroll_display_none();
      }

      &_footer,
      .estimate_detail_search_footer,
      .file_delete_dialog__footer {
        @include flex_box();
        @include flex_align_center();
        @include flex_content_center();
        width: 100%;
        background-color: #ffffff;
        padding: 0.5rem;
        .btn {
          margin: 0 5px;
          width: 100%;
          @include mq_min() {
            width: calc(100% / 2);
          }
        }
      }

      &.sp {
        /* 新規登録/編集、検索系ダイアログ */
        .search_box_sp_body_inner,
        .edit_sp_body_inner {
          padding: 0 0.75rem;
        }

        .category_wrap {
          padding: 1rem 0.5rem;

          &:not(:last-of-type) {
            border-bottom: 1px solid #cccccc;
          }

          .item_wrap {
            $tilde_margin: 5px;

            textarea {
              appearance: none;
            }

            > .full_width {
              width: 100%;
              .ui.selection.dropdown,
              > textarea {
                width: 100%;
              }
            }

            &:not(:first-of-type) {
              margin-top: 15px;
            }
            .TopLabelInputField_sp {
              > div {
                width: 100%;
              }
            }
            .item_label {
              @include flex_box();
              @include flex_align_end();
              margin-bottom: 3px;
              .btn {
                margin-left: auto;
                + .btn {
                  margin-left: 0.5rem;
                }
              }
            }
            .item_body {
              /* checkbox */
              &.item_checkbox {
                > div {
                  &.width_inputField {
                    @include flex_box();
                    @include flex_align_center();
                    .RightLabelCheckbox {
                      width: auto;
                    }
                    > div {
                      &:first-of-type {
                        > input {
                          width: 30px;
                          height: 30px;
                        }
                        > label {
                          width: 43px;
                        }
                      }
                      &.inputField {
                        flex: 1;
                        @include flex_box();
                        @include flex_align_center();
                        span {
                          padding: 0 5px;
                        }
                        div {
                          width: 100% !important;
                          input {
                            width: 100%;
                            height: 2rem;
                          }
                        }
                      }
                    }
                  }
                }
              }

              textarea {
                display: block;
                width: 100%;
                resize: none;
              }

              &.item_cardList {
                .body {
                  // height: 200px;
                  > .ui.segment {
                    padding: 0 !important;
                  }
                }
                .footer {
                  @include flex_box();
                  @include flex_content_around();
                  padding: 10px 0;
                  > .btn {
                    width: 30% !important;
                  }
                }
              } //&.item_cardList

              /* 郵便番号 */
              &.item_postal {
                @include flex_box();
                @include flex_align_center();
                > div {
                  @include flex_box();
                  @include flex_align_center();
                  input {
                    text-align: center;
                    padding: 0.5em;
                  }
                  &:first-of-type {
                    .input {
                      width: 4em;
                      input {
                        width: 100%;
                      }
                    }
                  }

                  &.hyphen {
                    margin: 0 10px;
                  }

                  &:last-of-type {
                    .input {
                      width: 5em;
                      input {
                        width: 100%;
                      }
                    }
                  }
                }
              } //&.item_postal

              /* メンテナンス日 */
              &.maintenance_date {
                .maintenance_date__buttons {
                  width: 100%;
                  @include flex_box;
                  @include flex_container_wrap;
                  gap: 0.5em;
                  margin-top: 0.5em;
                }
                .maintenance_date__button {
                  background-color: #fff;
                  color: $base_color;
                  border: 1px solid $base_color;
                  border-radius: 0.25em;
                  width: calc(100% / 3 - 0.5em * 2 / 3);
                  line-height: 3;
                  text-align: center;

                  &.selected {
                    background-color: $base_color;
                    color: #fff;
                    font-weight: bold;
                  }
                }
              }

              /* 期間系 */
              &.item_schedule {
                @include flex_box();
                @include flex_align_center();
                .item_schedule__form {
                  flex: 1;
                  input {
                    width: 100%;
                  }
                }

                .item_schedule__tilde {
                  margin: 0 #{$tilde_margin};
                }

                &.wrap {
                  // $margin_base: 3px;
                  @include flex_container_wrap();
                  // margin-top: -$margin_base;
                  .item_schedule__form {
                    flex: none !important;
                    width: 100%;
                    // margin: $margin_base 0;
                    @include mq_min() {
                      max-width: 13em;
                    }
                  }
                }
              }

              /* 区分系 */
              &.item_category {
                @include flex_box();
                @include flex_container_wrap();
                .radio {
                }
              }

              /* 年月日個別の期間系 */
              .item_period {
                &__inner {
                  @include flex_box();
                  @include flex_container_wrap();
                  > div {
                    @include flex_box();
                    @include flex_align_center();
                    .pulldown_select {
                      margin-right: 3px;
                    }
                  }

                  &__year {
                    width: 100%;
                    margin-bottom: 0.5rem;
                  }

                  &__month
                  /* &__day */ {
                    margin-right: 0.5rem;
                  }
                }
                .tilde {
                  margin: 0.5rem 0;
                }
              }

              /* ファイル選択 */
              .item_file_upload {
                &__img {
                  @include border-default($color: $form_border_color);
                  border-radius: 0.28571429rem;
                  padding: 1rem;
                  text-align: center;
                  // background-color: rgba($color: #cccccc, $alpha: .1);
                  img {
                    max-width: 100%;
                    max-height: 25vh;
                  }
                }
                &__buttons {
                  @include flex_box();
                  align-items: center;
                  > * {
                    width: calc(100% / 2);
                    @include mq_min() {
                      width: calc(100% / 4);
                    }
                    &.btn_tips {
                      width: auto;
                      margin-left: 10px;
                    }
                  }
                }
              }

              .input {
                width: 100%;
                max-width: 22em;

                input {
                  width: 100%;
                }
              }

              &.item_family_relation {
                max-width: 6em;
              }
              &.item_tel_no {
                width: 10em;
                max-width: 10em;
              }
              &.item_gender {
                max-width: 8em;
              }
              &.item_age {
                @include flex_box();
                @include flex_align_center();
                max-width: 7em;
                .input {
                  max-width: 5em;
                  margin-right: 5px;
                }
              }

              /* 顧客ランク */
              &.item_projectRank {
                @include flex_box();
                @include flex_align_center();
                > div {
                  &:first-of-type {
                    flex: 3;
                  }
                  &:not(:first-of-type) {
                    margin-left: 10px;
                    flex: 2;
                  }
                  min-width: inherit;
                }
              } //&.item_projectRank
            } //.item_body
            &.tags_form {
              + .tags_form {
                // &:not(:first-of-type) {
                margin-top: 2em;
              }
            }

            .dummy_tilde_space {
              width: calc(1em + #{$tilde_margin} * 2);
            }
          } //.item_wrap
        } //.category_wrap
      }
    }

    .message_dialog {
      height: 100%;
      @include flex_box();
      @include flex_direction_column();
      .base_dialog_content {
        > div {
          height: inherit !important;
        }
      }
      &__message {
        @include flex_box();
        @include flex_content_center();
        @include flex_align_center();
        min-height: 54px;
        flex: 1;
      }
      &__footer {
        @include flex_box();
        @include flex_content_center();
        padding: 0.5rem;
        > .btn {
          margin: 0 5px;
          width: 100%;
          @include mq_min() {
            width: calc(100% / 2);
          }
        }
      }
    }
  }

  &.sp {
    margin-top: 0 !important;
    .base_dialog_content {
      .message_dialog {
        &__message {
          min-height: 6em;
        }
      }
      .dialog_head_btn_wrap {
        padding: 0.5rem 0.5rem 0;
        button + button {
          margin-left: 0.5rem;
        }
      }

      &_inner {
        .list_base {
          margin: 0;
          flex: 1;
          overflow-y: auto;
          @include scroll_display_none;
        }

        &_footer {
          gap: 0 10px;
          .btn {
            margin: 0;
            max-width: 50%;
          }
        }
      }
    }
  }
} // BaseDialog
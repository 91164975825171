@use '../../../../../mixin' as mx;

.draglayer {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .accordion {
    @include mx.box_shadow($y: 0);

    &.daibunrui {
      .accordion__label {
        background-color: rgba($color: #90bbef, $alpha: 0.8);
        color: #fff;
      }
    }

    &.tyubunrui {
      .accordion__label {
        background-color: rgba($color: #eaf2fc, $alpha: 0.8);
        color: #000;
      }
    }
  }

  .card_preview {
    background-color: rgba($color: #fff, $alpha: 0.8);
    @include mx.box_shadow($y: 0);
    transform: translateY(-1rem);

    .col1 {
      margin-left: 3.05em;
      color: #000000de;
    }
    .col2 {
      padding: 0 0.5em;
    }
  }
}

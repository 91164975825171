@import "../../../../../App.scss";
@import "../../../../../mixin.scss";

.estimate_detail_multi_edit_sp {
  .item_wrap {
    align-items: center;
  }
  .checkbox_wrap {
    width: 40px;
  }
  .checkbox {
    label {
      margin-right: 0 !important;
    }
  }
  .checkbox_wrap + div {
    width: 100%;;
  }
}

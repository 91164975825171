@import "../../../../mixin";
@import "../../../../App";

.file_detail_sp {

  &__body{
    .thumb {
      > * {
        position: relative;
        height: auto !important;
        margin-bottom: 5px !important;
      }
    }
    &__table {
      .t_row {
        .t_header {
          width: 38vw !important;
          max-width: 9.5em !important;
        }
      }
    }
  }
  &__footer {
    .btn {
      flex: 1;
      + .btn {
        margin-left: 10px;
      }
    }
  }
}

/* ================ 受注画面用 ================ */
.detail_wrap.for_order {
  @include flex_box();
  @include flex_direction_column();
  height: 100%;
  font-size: 1rem;

  .detail_header {
    padding: 0.5rem 0.5rem 0;
    background-color: #fff;
    &_inner {
      display: flex !important;
      // @include flex_space_between();
      @include flex_align_center();
      display: block;
      height: 3em;
      padding: 0 0.5em;
      background-color: $header_background_gray;
      font-weight: bold;

      &__back_btn {
        margin-right: 0.5rem;
        color: $btn_dark_background_color;
        font-size: 1.5rem;
      }

      &__label {
        @include ellipsis();
        flex: 1;
      }
    }

    &_buttons {
      @include flex_box();
      margin-top: 0.25rem;
      .btn {
        flex: 1;
        font-size: 1rem;
        &:not(:first-of-type) {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .detail_body {
    flex: 1;
    padding: 0.25rem 0;
    overflow: hidden;
    position: relative;

    .list_base {
      border-top: none;
      width: 100%;
    } // list_base

    > div {
      height: 100%;
      @include flex_box();
      @include flex_direction_column();

      > .ui {
        &.menu {
          @include scroll_display_none();
          overflow-x: scroll;
          margin-bottom: 0.25rem;
          padding: 0 0.5rem;
          > a {
            min-width: calc(100% / 5 - 0.25em);
          }
        }
        &.attached:not(.message) + .ui.attached.segment:not(.top) {
          padding: 0 !important;
        }
      }
    }
  }

  /* 個別 */
  .file_detail_sp__body {
    padding: 0.5rem;
    &__table {
      @include scroll_display_none();
      flex: 1;
      overflow-y: scroll;

      .col_2 {
        @include flex_box();
        > div {
          &:first-of-type {
            flex: 1;
          }
          &:last-of-type {
            width: $detail_page_col2_width;
          }
        }
        + .col_2 {
          margin-top: 0.5rem;
        }
      }
      .thumb {
        position: relative;
        margin-left: 0 !important;
        margin-bottom: 5px !important;
        &::before {
          padding: 0;
        }
      }
    }
  }
}
.page_body_footer.for_order {
  display: flex;
  padding: 0.5rem;
  background-color: #ffffff;
}

@import '../../../../mixin';
@import '../../../../App';

.order_edit_sp {
  @include flex_box();
  @include flex_direction_column();
  height: 100%;
  .file_btn_wrap {
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    div {
      width: 100%;
      padding:0 0.5em;
      button {
        width: 100%;
      }
    }
    border-bottom: 1px solid $border_default_color;
  }
  
  .page_body_header {
    .bottom_border_button {
      width: calc(100% / 2);
      margin: 0 5px;
    }
  }

  &__body {
    @include scroll_display_none();
    flex: 1;
    overflow-y: scroll;
  }
}
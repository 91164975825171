@import "../../../App";
@import "../../../mixin";
body.pc{
.show_image_dialog {
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  width: 100%;
  height: 100%;
  padding: 1rem;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
}
body.sp{
  .show_image_dialog {
    @include flex_box();
    @include flex_align_center();
    @include flex_content_center();
    width: 100%;
    height: calc(100vh / 2);
    padding: 1rem;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
@import "../../../../../App.scss";
@import "../../../../../mixin.scss";

.changePasswordSP {
  padding: 0 35px;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  height: 100%;
  &__title {
    font-size: 1.8rem;
    color: $base-color;
  }
  &__body {
    margin-top: 30px;
    &__inner {
      &__text {
        text-align: left;
        margin-top: 20px;
      }
      &__input-field {
        font-size: 14px;
        text-align: left;
        margin: 10px 0 30px;
        &:first-child {
          margin: 0;
        }
      }
      .btn {
        width: 200px;
      }
    }
  }
}

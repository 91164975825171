@import '../../../setting';

.base_input {
  .ui.input {
    width: 100%;
  }
}
.align_r {
  input {
    text-align: right !important;
  }
}

.width_large {
  input {
    width: 250px !important;
  }
}

/*selectの絞り込み*/
body.pc {
  .react_select {
    height: 30px;
    > div {
      min-height: 28px;
      z-index: 5;
      &:hover {
        border-color: $base-color;
      }
      > div {
        padding: 0 8px;
        + div {
          padding: 0 10px;
        }
        > div + div {
          margin: 0;
          padding-top: 0px;
          padding-bottom: 0px;
          height: 28px;
        }
        & + div {
          > div {
            padding: 0;
            svg {
              display: none;
            }
            &::after {
              position: absolute;
              top: 12px;
              right: 7px;
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 6px 6px 0 6px;
              border-color: #5F6368 transparent transparent transparent;
              pointer-events: none;
              z-index: 1;
            }
          }
        }
        > span {/*border*/
          display: none;
        }
      }
      + div {
        margin: 4px 0;

      }
    }
    input {
      border: none !important;
      width:auto !important;
    }
  }
}

body.sp {
  .react_select {
    height: 44px;
    > div {
      min-height: 44px;
      z-index: 1;
      &:hover {
        border-color: $base-color;
      }
      > div {
        padding: 0 8px;
        + div {
          padding: 0 10px;
        }
        > div + div {
          margin: 0;
          padding-top: 0px;
          padding-bottom: 0px;
          height: 28px;
        }
        & + div {
          > div {
            padding: 0;
            svg {
              display: none;
            }
            &::after {
              position: absolute;
              top: 0;
              bottom: 0;
              right: calc(1em - 6px);
              margin: auto;
              width: 0;
              height: 0;
              padding: 0;
              content: "";
              border-left: 6px solid transparent;
              border-right: 6px solid transparent;
              border-top: 6px solid #5f6368;
              pointer-events: none;
            }
          }
        }
        > span {/*border*/
          display: none;
        }
      }
      + div {
        margin: 4px 0;

      }
    }
    input {
      border: none !important;
      width:auto !important;
    }
  }
}

@import "../../../../mixin.scss";
.RightLabelCheckbox {
  @include flex_box();
  @include flex_align_center();
  &__label {
    &.left {
      margin-right: 10px;
    }
    &.right {
      margin-left: 10px;
      flex: 1;
    }
  }
  &__form {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }
}

@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.file_edit_sp {
  .item_label {
    .btn {
      width: 7.5em;
    }
  }
  .file_box {
    align-items: center;
    .base_input {
      flex: 1;
    }
    .extension {
      width: 50px;
      text-align: center;
    }
  }
}
@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.customerEditSP {
  position: relative;
  .category_wrap {
    .item_wrap {
      /**
       * 個別対応
       */
      .item_body {
        /* 地図から検索 */
        &.item_map {
          width: 100%;
          .btn {
            display: block;
            width: 8rem;
            margin-left: auto;
          }
        }

        // 顧客名
        &.item_customer {
          @include flex_box();
          @include flex_align_start();
          > div {
            &:first-of-type {
              flex: 2;
            }
            &:not(:first-of-type) {
              margin-left: 10px;
              flex: 1;
            }
            min-width: inherit;
          }
        }

        // 顧客ランク
        &.item_customerRank {
          @include flex_box();
          @include flex_align_center();
          > div {
            &:first-of-type {
              // width: 160px !important;
              flex: 3;
            }
            &:not(:first-of-type) {
              margin-left: 10px;
              flex: 2;
            }
            min-width: inherit;
          }
        } //&.item_customerRank

        &.item_madori {
          > div {
            width: 160px !important;
            min-width: inherit;
          }
        }

        // 築年数
        &.item_years {
        } //&.item_years

        // 備考
        &.item_remarks {
        } //&.item_remarks

        &.item_cardList {
          table {
            $minHeight: 8em;
            min-height: $minHeight;

            .no_data_guidance {
              padding: 0;
              height: calc(#{$minHeight} - 2.315em);  // 2.3em ... およそthead分の高さ
            }
          }
  
        }
  
      } //.item_body
      &.tags_form {
        &:not(:first-of-type) {
          margin-top: 1em;
        }
      }

    } //.item_wrap
  } //.category_wrap
} // customerEditSP

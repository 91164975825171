@use '../../../../setting' as *;

.list_base {
  // max-width: 46rem;
  height: 100%;
  padding: 0 0.5rem;
  margin: 0 auto;
  position: relative;

  &_card_wrap {
    position: relative;
    &::before {
      display: block;
      content: "";
      width: calc(100%);
      height: 1px;
      background-color: $border_secondary;
      position: absolute;
      top: 100%;
      left: 0;
    }
    border-bottom: 1px solid transparent;
    &:first-child {
      //border-top: 1px solid $border_secondary;
    }

    &.active {
      .card_base {
        background-image: none !important;
        background-color: $selectecd_color !important;
      }
    }
  }

  .ui.dimmer {
    z-index: 10000000;
  }

  &__get_loading {
    position: relative;
  }

  &.sp {
    .list_base__get_loading {
      height: 5em;
    }
  }

  &.pc {
    .list_base__get_loading {
      height: 6em;
    }
  }
}

@use '../../../mixin' as mx;
@use '../../../setting' as st;

.accordion.sp {
  //position: relative;
  .label {
  }
  .btn.change_print_name {
    /*position: absolute;
    top: 1em;
    right: 1em;*/
    font-size: small;
    min-height: 1em;
    margin-left: auto;

  }
  .accordion__label {
    @include mx.flex_box;
    @include mx.flex_align_center;
    height: 3em;
    gap: 0.25em;
    @include mx.ellipsis;
    padding: 0.25em;
    @include mx.box_shadow;
    position: relative;

    @include mx.mq_max(xs) {
      height: 2.5em;
    }
    &.ancestor {
      // background-color: #1451A1;
      background-color: #619be8;
      color: #fff;
    }

    &.daibunrui {
      background-color: #619be8b0;
      color: #fff;
      &.dnd_target {
        background-color: rgba($color: st.$emphasis_color2, $alpha: .8);
      }

    }

    &.tyubunrui {
      background-color: #619be820;
      color: #000;
      &.dnd_target {
        background-color: rgba($color: st.$emphasis_color2, $alpha: .6);
      }
    }

    &__dnd {
      @include mx.flex_box;
      @include mx.flex_align_center;
      gap: 0.25em;
    }

    .triangle_icon {
      // dndアイコン素材に大きさ揃えてる
      width: 24px;
      height: 24px;
    }

    .dnd_handler {
      position: absolute;
      width: 30%;
      height: 100%;  
    }
    // .accordion__label
  }
  .estimate_detail_card_sp {
    &.dnd {
      gap: 0.5em;
      .col1 {
        color: inherit;
        width: 1em;
        margin-left: 3.05em;
        margin-right: 0;
      }
      .col2 {
        padding: 0 0.5em;
        &__row2 {
          width: 100%;
          // @include mx.flex_direction_column;

          &__con1 {
            // width: 100%;
            text-align: left;
          }
          &__con2 {
            // width: 100%;
            flex: 0;
            white-space: nowrap;
          }
        }
      }
    }
  }

  // .accordion.sp
}

@import "../../../../../../../App.scss";
@import "../../../../../../../_common.scss";
@import "../../../../../../../_form.scss";

.another_window_import {
  display: flex;
  height: 100%;
  flex-direction: column;
  header {
    border-bottom: 1px solid $border_default_color;
    padding: 15px;
    font-size: 1.5rem;
    color: $base-color;
  }
  div.table_wrap {
    flex: 1;
    overflow-y: auto;
    padding: 15px;
  }
  footer {
    border-top: 1px solid $border_default_color;
    text-align: center;
    padding: 5px;
    button {
      width: 100px;
      min-height: 30px;
    }
  }
}
@import '../../../../mixin.scss';
@import "../../../../App.scss";

body.pc {
    .App {
        .headerPC {
            @include box_shadow();
            z-index: 1;
            &__top {
                display: flex;
                border: none !important;
                //border-bottom: 2px solid $base-color !important;
                justify-content:space-between;
                line-height: 34px;
                padding: 0 15px;
                h1 {
/*
                    color: $base-color;
                    line-height: 34px;
                    font-size: 22px;
                    font-weight: normal;
*/
                    img {
                        vertical-align: middle;
                        height: 44px;
                    }
                }
                .select_company_box {
                    align-items: center;
                    margin-left: auto;
                    margin-right: 30px;
                    font-size: 14px;
                    strong {
                        color: $base-color;
                    }
                    button {
                        margin-left: 15px;
                    }
                }
                .account_box {
                    display: flex;
                    line-height: 42px;
                    font-size: 14px;
                }
                button.logout {
                    min-width: 30px !important;
                    /*height: 34px;*/
                    margin-left: 10px;
                    vertical-align: bottom;

                }
            }
            &__bottom {
                @include flex_box();
                padding: 0 15px;
                &__menu {
                    white-space: nowrap;
                    height: 40px;
                    font-size: 14px;
                    line-height: 40px;
                    min-width: 80px;
                    cursor: pointer;
                    padding: 0 10px;
                    text-align: center;
                    border-bottom: 5px solid #FFFFFF;
                    &:hover {
                        
                        border-bottom: 5px solid #DFE1E5;
                    }
                    &.active {
                        color: $base-color;
                        border-bottom: 5px solid $base-color;
                    }
                }
            }
        }
    }
    .MuiPopover-paper {
        //@include box_shadow();
        box-shadow: 0px 3px 6px #00000033 !important;
        top: 40px !important;
        .profile {
            padding:5px 15px;
            h2 {
                text-align: center;
                margin-bottom: 10px !important;
                background-color: $base-color;
                color: #FFF;
                padding: 5px;
                font-weight: normal;
            }
            .item_wrap {
                .item_box {
                    width: 100%;
                    .item_head {
                        min-width: 120px;
                        text-align: center;
                        + div {
                            flex: 1;
                            text-align: left;
                        }
                    }
                }
            }
        }
        hr {
            margin: 5px 0 10px;
        }
        .btn_box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0;
            button {
                width: 200px;
                + button {
                    margin-top: 5px;
                }
            }
        }
    }
}
@import "../../../../../mixin";

.change_password_dialog_sp {
  @include flex_box();
  @include flex_direction_column();
  @include flex_align_center();
  text-align: center;

  .TopLabelInputField_sp {
    width: calc(100% - 2rem);
    margin-top: 1rem;

    @include mq_min() {
      max-width: 60%;
    }
  
    + .TopLabelInputField_sp {
      margin-bottom: 1rem;
    }
  }
}

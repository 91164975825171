body.pc {
    .multi_edit_meisai_dialog {
        .checkbox {
            label {
              margin-right: 0 !important;
            }
        }
        .checkbox_wrap {
            width: 38px;
        }
    }
}

@import "../../../setting";

body.sp {
  .pulldown_select{
    position: relative;
  }
  .selector {
    width: 100%;
    border: 1px solid $form_border_color;
    border-radius: 0.28571429rem;
    // padding: 0.67857143em 0 0.67857143em 1em;
    background-color: #fff;
    position: relative;


    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(1em - 6px);
      margin: auto;
      width: 0;
      height: 0;
      padding: 0;
      content: "";
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #5f6368;
      pointer-events: none;
    }

    &.error {
      border: 1px solid $form_error_border_color;
      background-color: $form_error_bg_color;
      select {
        color: $form_error_text_color;
      }
    }

    select {
      width: 100%;
      padding: 0.67857143em 2em 0.67857143em 1em;
      cursor: pointer;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: transparent;
      background-image: none;
      box-shadow: none;
      -webkit-appearance: none;
      appearance: none;
      line-height: 1.36em;
      -webkit-tap-highlight-color: rgba(143, 143, 143, 0.05);
      &:disabled {
        background-color: $form_disabled_color !important;
        //color: rgba(0,0,0,.87) !important;
        opacity: 1 !important;
      }

      &::-ms-expand {
        display: none;
      }
    }
  }
}

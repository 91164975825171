@import "../../../../../App.scss";
@import "../../../../../mixin.scss";

.estimate_detail_info_sp {
  padding: 1rem;
  .t_row {
    .t_header {
      max-width: 6em !important;
    }
  }
  &_bottom {
    @include flex_box();
    @include flex_content_center();
    padding: 1em;
  }
}
.estimate_detail_sp__container__price_detail__body {
  .t_row {
    align-items: center;
  .t_header {
      font-size: 0.8em;
      text-align: center;
    }
    .t_body {
      display: block !important;
      margin-left: auto;
      text-align: right;
    }
  }
}

@import "../../../../App.scss";
@import "../../../../mixin.scss";

.maintenance_sp {
  &__list {
    @include flex_box();
    @include flex_direction_column();
    height: 100%;
    &__header {
    }

    &__body {
      @include scroll_display_none();
      flex: 1;
      overflow-y: scroll;
    }
  }

  .map_list_header {
    &::after {
      height: 0 !important;
    }
  }

  .page_body_footer {
    .LeftIconButton {
      font-size: 0.9rem !important;
    }
  }
}

@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.sendPasswordSP {
  text-align: center;
  padding: 0 35px;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  height: 100%;
  &__title {
    font-size: 1.8rem;
    color: $base-color;
    //margin-top: 60px;
  }
  &__body {
    margin-top: 30px;
    &__inner {
      &__input-field {
        text-align: left;
        margin-top: 30px;
        font-size: 14px;
      }
      &__message {
        text-align: left;
        margin-top: 30px;
        //font-size: 16px;
      }
      &__sendBtn {
        margin-top: 30px;
      }
      &__backLink {
        color: #000;
        margin-top: 30px;
        font-size: 16px;
      }
      button {
        width: 200px;
        height: 35px;
      }
    }
  }
}

@import "../../../../App.scss";
@import "../../../../mixin.scss";

.file_card {
  .numero {
    text-align: center;
    background-color: $emphasis_color2;
    line-height: 1.5em;
  }
  .card_base_row {
    &__col_right {
      .btn {
        width: 100%;
        margin-bottom: 0 !important;
      }
      .thumb {
        img {
          /*width:auto;
          height:auto;
          max-width:100%;
          max-height:100%;*/
          width:100%;
          height:100%;
          object-fit: cover;
          //object-fit: contain;
        }
      }
    }
  }
}

@import "../../../../App.scss";
@import "../../../../mixin.scss";

.estimate_detail_secondary_card {
  padding: 0.75rem 1rem 0.75rem 2rem !important;
  &.is_select {
    background-color: $selectecd_color;
    background-image:url(../../../../asset/images/icon/deposite.svg) ;
    //background-color:rgba(255,255,255,0);
    //background-blend-mode:lighten;
    background-repeat: no-repeat;
    background-position: 5px 50%;
    background-size:20px 20px;
  }

  &__row1 {
    font-weight: bold;
  }

  &__row2 {
    @include flex_box();
    > div {
      flex: 1;
    }

    &__col2 {
      text-align: right;
    }
  }
}

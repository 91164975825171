@import '../../../../App.scss';

.registrationAddressMapDialog {
  // * {
  //   border-bottom-left-radius: .28571429rem;
  //   border-bottom-right-radius: .28571429rem;
  // }
  .btn {
    &.search-address {
      width: 110px;
      border: 1px solid $base_color;
      position: absolute;
      top: 10px;
      left: 10px;
    }
    &.search-customer,
    &.search-project {
      width: 110px;
      border: 1px solid $base_color;
      position: absolute;
      top: 57px;
      left: 10px;
    }
  }
}
.for_registration {
  position: absolute;
  z-index: 10;
  width: 180px;
  left: calc(50% - 90px);
  bottom: 20px;
}
@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.projectSearchBoxSP {
  .category_wrap {
    .item_wrap {
      /**
       * 個別対応
       */
      .item_body {
        // 郵便番号
        &.item_postal {
          @include flex_box();
          @include flex_align_center();

          > div {
            &:first-of-type {
              width: 4em;
              .input {
                width: 100%;
              }
            }
            &:nth-of-type(2) {
              width: 30px;
              text-align: center;
            }
            &:last-of-type {
              width: 5em;
              .input {
                width: 100%;
              }
            }
            > div {
              max-width: 100%;
            }
          }
        } //&.item_postal

        // 顧客ランク
        &.item_projectRank {
          @include flex_box();
          @include flex_align_center();
          > div {
            &:first-of-type {
              flex: 3;
            }
            &:not(:first-of-type) {
              margin-left: 10px;
              flex: 2;
            }
            min-width: inherit;
          }
        } //&.item_projectRank

        // エリア
        &.item_area,
        &.item_madori {
          > div {
            width: 160px !important;
            min-width: inherit;
          }
        }

        // 築年数
        &.item_years {
          @include flex_box();
          @include flex_align_center();

          > div {
            min-width: inherit;

            &:first-of-type {
              flex: 3;
              input {
                width: 100%;
              }
            }
            &:last-of-type {
              flex: 1;
              margin-left: 10px;
            }
          }
        } //&.item_years

        //完工時期
        &.item_Period {
          .inner {
            @include flex_box();
            @include flex_align_center();

            &:not(:first-of-type) {
              margin-top: 10px;
            }
            > div {
              min-width: inherit;

              &:not(:last-of-type) {
                margin-right: 10px;
              }
              &.selection {
                width: 7rem;
              }
            }
          }
        } //&.item_Period

        &.item_checkbox {
          display: grid;
          grid-template-columns: 1fr 1fr;
          text-align: left;
          word-break: break-all;
          > div {
            margin-top: 0.5em;
            > input {
              width: 30px;
              height: 30px;
            }
          }
        } // &.item_checkbox

      } //.item_body
      &.tags_form {
        &:not(:first-of-type) {
          margin: 1em 0;
        }
      }
    } //.item_wrap
  } //.category_wrap
} // searchBoxCustomerSP

@import "./mixin.scss";

$const-inset-top: constant(safe-area-inset-top);
$const-inset-right: constant(safe-area-inset-right);
$const-inset-bottom: constant(safe-area-inset-bottom);
$const-inset-left: constant(safe-area-inset-left);

$env-inset-top: env(safe-area-inset-top);
$env-inset-right: env(safe-area-inset-right);
$env-inset-bottom: env(safe-area-inset-bottom);
$env-inset-left: env(safe-area-inset-left);

:root {
  font-size: 16px;
}

#root {
  font-family: "Roboto", "Noto Sans JP", "Helvetica", "Arial", sans-serif;
  height: 100%;
  .App {
    height: calc(100% - #{$env-inset-bottom});
  }
}

.btn-color-set1 {
  background-color: $base_color_thin !important;
  color: $base_color !important;
  border: 1px solid $base_color !important;
}

.important {
  font-size: 1rem;
  color: $base-color;
  font-weight: bold;
}

/* semantic ui */
.ui.disabled.input > input {
  background-color: #cccccc;
}

/* 副次ヘッダー */
.secondary_header {
  @include flex_box();
  @include flex_align_center();
  height: 40px;
  padding: 0 10px;
  background-color: $base_color;
  color: #fff;
}

/* footer */
.base_footer {
  padding: 3px 5px !important;
  border-top: solid 2px $base_color;
  width: 100%;
  background-color: #fff;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  .btn {
    margin: 0 5px;
  }
}

.two_stage_footer {
  padding: 3px 5px !important;
  border-top: solid 2px $base_color;
  width: 100%;
  background-color: #fff;
  &_stage {
    @include flex_box();
    @include flex_content_around();
    @include flex_align_center();
    .btn {
      width: 100% !important;
      margin: 0 5px !important;
    }
  }
}

/* 装飾ボタン */
.bottom_border_box {
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  font-size: 1rem;
  border-bottom: 2px solid $border_secondary;
  position: relative;
  width: 6em;
  padding: 0.5em;
  user-select: none;

  &::after {
    background-color: $base_color;
    content: "";
    display: block;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -2px;
    transform: scaleX(0);
  }

  /* アニメーションにしたい場合、要クラス名付与 */
  &.animation {
    &:after {
      transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
    }
  }

  /* 選択中用セレクタ 要クラス名付与 */
  &.selected {
    &::after {
      transform: scaleX(1);
    }
  }

  img {
    max-height: 18px;
    margin-right: 5px;
  }
}

.show_type_label {
  color: #fff !important;
  &.ob {
    background-color: #0099cc !important;
    @include border-default($color: #0099cc);
  }
  &.mikomi {
    background-color: #d24444 !important;
    @include border-default($color: #d24444);
  }
  &.project_rank_1 {
    //完工
    background-color: #0099cc !important;
    @include border-default($color: #0099cc);
  }
  &.project_rank_1 {
    //完工
    background-color: #0099cc !important;
    @include border-default($color: #0099cc);
  }
  &.project_rank_1 {
    //完工
    background-color: #0099cc !important;
    @include border-default($color: #0099cc);
  }
}

/* サムネ用スタイル */
.thumb {
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: bottom;

  &::before {
    display: block;
    content: "";
    padding-top: 75%;
  }

  &.google {
    &::before {
      display: block;
      content: "";
      padding-top: 100%;
    }
  }

  .no_image {
    @include flex_box();
    @include flex_align_center();
    @include flex_content_center();
    border: 1px solid $border_secondary;
    width: 100%;
    height: 100%;
    font-size: 0.9rem;
    background-color: #0000001a;
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    height: 100%;
    width: 100%;
    box-shadow: 0 0 2px 0 rgba($color: #000000, $alpha: 0.4);
    &.no_shadow {
      box-shadow: none;
    }
  }
}

.rect_label {
  padding: 0;
  min-width: 80px;
}

.card_base {
  &_row {
    @include flex_box();
    word-break: break-all;

    .card_info_item {
      + .card_info_item {
        margin-top: 5px;
      }
    }

    &__col_left {
      flex: 1 !important;
      @include flex_box();
      @include flex_direction_column();
      @include flex_space_between();
    }

    &__col_right {
      @include flex_box();
      @include flex_direction_column();
      min-width: 70px;
      margin-left: 5px;
      > *,
      > div > * {
        margin-bottom: 5px !important;
      }

      > *:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }
}

/* カード内の情報用スタイル */
.card_info_item {
  @include flex_box();
  @include flex_align_start();
  width: 100%;
  &__head {
    /* 使用先で適宜調整 */
    min-width: 4em;
    position: relative;
    padding-right: 5px;
    margin-right: 5px;

    &::after,
    &::before {
      $size: 2px;
      display: block;
      position: absolute;
      content: "";
      width: $size;
      height: $size;
      border-radius: 50%;
      background-color: #00000080;
      right: 0;
    }
    &::before {
      top: 28%;
    }
    &::after {
      bottom: 30%;
    }
  }
  &__text {
    flex: 1;
  }
}

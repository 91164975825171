@import "../../../../../mixin.scss";
@import "../../../../../App.scss";

.support_history_edit_sp {
  .category_wrap {
    .item_wrap {
      .item_label {
        .icon_button {
          // width: 42px;
          // height: 42px;
        }
      }
    }
  }
  .file_box {
    align-items: center;
    .base_input {
      flex: 1;
    }
    .extension {
      width: 50px;
      text-align: center;
    }
  }
} // support_history_edit_sp

@import '../../../../App';
@import '../../../../mixin';

.maintenance_detail_sp {
  &__edit_btn {
    position: absolute;
    top: 0;
    right: 0.5rem;
    z-index: 200;
  }

  &__footer {
    padding: 0.5rem 0;
    .btn {
      display: block;
      margin: auto;
    }
  }
}
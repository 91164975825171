@import "../../../mixin.scss";

.detail_info_table {
  overflow-x: hidden;
  padding: 0.25rem 0.5rem;
  .t_row {
    .t_header {
      max-width: 8em !important;
      @include mq_min() {
        max-width: 12em !important;
      }
    }

    &.block {
      display: block !important;
      .t_header {
        justify-content: start !important;
        width: 100% !important;
        max-width: 100% !important;
        padding: 0.5rem 1rem !important;
      }
      .t_body {
        border: 1px solid $border_secondary;
        border-radius: 0.3rem;
        padding-left: 0 !important;
        width: calc(100% - 1rem);
        margin: 0.5rem auto;
      }
    }
  }
}

@import "../../../../mixin.scss";

.show_type_label {
  @include flex_box();
  @include flex_align_center();
  border: 1px solid #cccccc;
  background-color: #ffffff;
  > img {
    height: 1.5em;
  }
  > span {
    flex: 1;
    text-align: center;
    font-size: 1em;
    color: /*$base_color;*/#FFF;
  }
  &.ob {
    background-color: #0099CC !important;
    border: 1px solid #0099CC !important;
    
  }
  &.mikomi {
    background-color: #D24444 !important;
    border: 1px solid #D24444 !important;
  }
  &.project_uncontracted {
    background-color: #D24444 !important;
    border: 1px solid #D24444 !important;
  }
  &.project_construction {
    background-color: #0A7B24 !important;
    border: 1px solid #0A7B24 !important;
  } 
  &.project_ob {
    background-color: #0099CC !important;
    border: 1px solid #0099CC !important;
  }
  &.project_etc {
    background-color: #5F6368 !important;
    border: 1px solid #5F6368 !important;
  }
  &.maintenance_started {
    background-color: #D24444 !important;
    border: 1px solid #D24444 !important;
  }
  &.maintenance_completed {
    background-color: #0099CC !important;
    border: 1px solid #0099CC !important;
  }

  




}

body.sp {
  .show_type_label {
    height: 2em;
    padding: 0.25em;
  }
}

body.pc {
  .show_type_label {
    padding-left: 2px;
    > img {
      height: 22px;
    }
  }
}

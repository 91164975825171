.RightLabelInputField_pc {
  &__label {
    margin-left: 5px;
  }
  &__field {
    margin-left: 0;
  }
  &.small {
    input {
      width :100px;
    }
  }
}

.RightLabelInputField_sp {
  display: flex;
  align-items: center;
  &__label {
    display: block;
    margin-left: 5px;
  }
  &__field {
    display: block;
    margin-top: 7px;
  }
  &.small {
    input {
      width :100px;
    }
  }
}
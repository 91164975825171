@import '../../../../../App.scss';
@import '../../../../../mixin.scss';

.changePasswordCompleteSP {
  text-align: center;
  padding: 0 35px;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  height: 100%;
  &__title {
    font-size: 1.8rem;
    color: $base-color;
  }
  &__body {
    margin-top: 30px;
    font-size: 16px;
    &__inner {
      &__text {
        text-align: left;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
      &__btn {
        margin-top: 50px;
        .btn {
          width: 200px;
        }
      }
    }
  }
}

@use '../../../../../../mixin' as mx;

.estimate_detail_list_sp {
  padding: 0.5rem;

  .estimate_detail_card_sp {
    &:not(.last_type) {
      position: relative;
      &::after {
        @include mx.pseudo_hor_border;
        bottom: 0;
      }
    }
  }
}
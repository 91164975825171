@import '../../../../../App.scss';
@import '../../../../../mixin.scss';

.sendPasswordCompleteSP {
  text-align: center;
  padding: 0 35px;
  @include flex_box();
  @include flex_align_center();
  @include flex_content_center();
  height: 100%;
  &__title {
    font-size: 1.8rem;
    color: $base-color;
    //margin-top: 40px;
  }
  &__body {
    margin-top: 30px;
    &__inner {
      &__text{
        text-align: left;
      }
      .btn {
        width: 200px;
        margin-top: 30px;
      }
    }
  }
}

@import "../../../../../mixin.scss";
@import "../../../../../App.scss";
body.pc {
  .MaintenanceInfoWindow {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 20;
    &__arrow-btn {
      background-color: $base_color;
      color: $font-white;
      text-align: center;
    }

    &__info {
      background-color: #FFF;
      padding: 9px 0;
      &__row {
        @include flex_box();
        padding: 9px 0 9px 9px;
        &.row1 {
          > .maintenance-name {
            flex: 1;
            word-break: break-all;
          }
          > .col {
            width: 128px;
            @include flex_box();
            @include flex_content_around();
            @include flex_align_center();
            margin-left: auto;
            > .maintenance-rank,
            .maintenance-type {
              width: 52px;
              text-align: center;
              color: $font-white;
              background-color: $bg_dark;
            }
          }
        } // row1
        &.row2 {
          > .maintenance-address {
            word-break: break-all;
          }
        }
        &.row3 {
          > .maintenance-tel {
            width: 170px;
          }
          > .maintenance-salesStaff {
            flex: 1;
            word-break: break-all;
          }
        } // row3
      } // MaintenanceInfoWindow__info
    }

    &__footer {
      height: 52px;
      background-color: #FFF;
      border-top: solid 1px $base_color;
      @include flex_box();
      @include flex_content_around();
      @include flex_align_center();
      .button {
        width: 90px !important;
        height: 34px !important;
        padding: 0;
        background-color: $base_color_thin !important;
        border: none !important;
        box-shadow: 0 0 2px;
        &:hover {
          background-color: $hover-color !important;
          box-shadow: 0 0 2px;
        }
        .fa-mobile-alt {
          font-size: 28px;
        }
        .fa-line {
          font-size: 31px;
        }
      }
    } // MaintenanceInfoWindow__info
  } // MaintenanceInfoWindow
  .maintenance_date {
    border-color: $base_color !important;
    text-align: center;
    th,td {
      border-color: $base_color !important;
      padding: 0;
    }
    th {
      background-color: $base_color;
      color: #FFF;
    }
    td {
      color: $base_color; 
    }
    &.alert {
      border-color: $alert_color !important;
      th,td {
        border-color: $alert_color !important;
      }
      th {
        background-color: $alert_color;
      }
      td {
        color: $alert_color;
      }
    }
  }
}